export const CardsDoctors = () => import('../../components/cards/Doctors.vue' /* webpackChunkName: "components/cards-doctors" */).then(c => wrapFunctional(c.default || c))
export const CardsRecruitments = () => import('../../components/cards/Recruitments.vue' /* webpackChunkName: "components/cards-recruitments" */).then(c => wrapFunctional(c.default || c))
export const HomeActivities = () => import('../../components/home/Activities.vue' /* webpackChunkName: "components/home-activities" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/home/Banner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeDoctorSlide = () => import('../../components/home/DoctorSlide.vue' /* webpackChunkName: "components/home-doctor-slide" */).then(c => wrapFunctional(c.default || c))
export const HomeDoctors = () => import('../../components/home/Doctors.vue' /* webpackChunkName: "components/home-doctors" */).then(c => wrapFunctional(c.default || c))
export const HomeFeedBack = () => import('../../components/home/FeedBack.vue' /* webpackChunkName: "components/home-feed-back" */).then(c => wrapFunctional(c.default || c))
export const HomeNews = () => import('../../components/home/News.vue' /* webpackChunkName: "components/home-news" */).then(c => wrapFunctional(c.default || c))
export const HomeSpecialists = () => import('../../components/home/Specialists.vue' /* webpackChunkName: "components/home-specialists" */).then(c => wrapFunctional(c.default || c))
export const IntroductionTable = () => import('../../components/introduction/Table.vue' /* webpackChunkName: "components/introduction-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutDialog = () => import('../../components/layout/Dialog.vue' /* webpackChunkName: "components/layout-dialog" */).then(c => wrapFunctional(c.default || c))
export const LayoutInforDialog = () => import('../../components/layout/InforDialog.vue' /* webpackChunkName: "components/layout-infor-dialog" */).then(c => wrapFunctional(c.default || c))
export const LayoutTheFooter = () => import('../../components/layout/TheFooter.vue' /* webpackChunkName: "components/layout-the-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutTheHeader = () => import('../../components/layout/TheHeader.vue' /* webpackChunkName: "components/layout-the-header" */).then(c => wrapFunctional(c.default || c))
export const FiltersDate = () => import('../../components/filters/Date.vue' /* webpackChunkName: "components/filters-date" */).then(c => wrapFunctional(c.default || c))
export const FiltersDateRange = () => import('../../components/filters/DateRange.vue' /* webpackChunkName: "components/filters-date-range" */).then(c => wrapFunctional(c.default || c))
export const FiltersSearch = () => import('../../components/filters/Search.vue' /* webpackChunkName: "components/filters-search" */).then(c => wrapFunctional(c.default || c))
export const FiltersSelect = () => import('../../components/filters/Select.vue' /* webpackChunkName: "components/filters-select" */).then(c => wrapFunctional(c.default || c))
export const FiltersSelectRemote = () => import('../../components/filters/SelectRemote.vue' /* webpackChunkName: "components/filters-select-remote" */).then(c => wrapFunctional(c.default || c))
export const SharedBreadcrumb = () => import('../../components/shared/Breadcrumb.vue' /* webpackChunkName: "components/shared-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const SharedChart = () => import('../../components/shared/Chart.js' /* webpackChunkName: "components/shared-chart" */).then(c => wrapFunctional(c.default || c))
export const SharedComingSoon = () => import('../../components/shared/ComingSoon.vue' /* webpackChunkName: "components/shared-coming-soon" */).then(c => wrapFunctional(c.default || c))
export const SharedConfirmDialog = () => import('../../components/shared/ConfirmDialog.vue' /* webpackChunkName: "components/shared-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const SharedCountUp = () => import('../../components/shared/CountUp.vue' /* webpackChunkName: "components/shared-count-up" */).then(c => wrapFunctional(c.default || c))
export const SharedEmptyPage = () => import('../../components/shared/EmptyPage.vue' /* webpackChunkName: "components/shared-empty-page" */).then(c => wrapFunctional(c.default || c))
export const SharedFeature = () => import('../../components/shared/Feature.vue' /* webpackChunkName: "components/shared-feature" */).then(c => wrapFunctional(c.default || c))
export const SharedLoading = () => import('../../components/shared/Loading.vue' /* webpackChunkName: "components/shared-loading" */).then(c => wrapFunctional(c.default || c))
export const SharedLoadingSecond = () => import('../../components/shared/LoadingSecond.vue' /* webpackChunkName: "components/shared-loading-second" */).then(c => wrapFunctional(c.default || c))
export const SharedPagination = () => import('../../components/shared/Pagination.vue' /* webpackChunkName: "components/shared-pagination" */).then(c => wrapFunctional(c.default || c))
export const SharedScrollToTop = () => import('../../components/shared/ScrollToTop.vue' /* webpackChunkName: "components/shared-scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const SharedSuccessDialog = () => import('../../components/shared/SuccessDialog.vue' /* webpackChunkName: "components/shared-success-dialog" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
